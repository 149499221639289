import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ExternalLink from '@common/ExternalLink';
import styled from 'styled-components';
import { Container } from '@components/global';

import { Nav } from './style';
import InstagramIcon from '@static/icons/instagram.svg';
import YoutubeIcon from '@static/icons/youtube.svg';
import FacebookIcon from '@static/icons/facebook.svg';

import { ReactComponent as PLZLogo } from '@images/logos/plz.svg';

const SOCIAL = [
  {
    icon: YoutubeIcon,
    link:
      'https://www.youtube.com/channel/UCnSoBC7gdEQxaBcgGoXa_2Q?view_as=subscriber',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/panalazat/',
  },
  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/panalazat/',
  },
];

class Navbar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  getNavAnchorLink = item => (
    <AnchorLink href={item.location} onClick={this.closeMobileMenu}>
      {item.name}
    </AnchorLink>
  );

  render() {
    return (
      <Nav>
        <StyledContainer>
          <PLZLogo />
          <SocialIcons>
            {SOCIAL.map(({ icon, link }) => (
              <ExternalLink key={link} href={link}>
                <img src={icon} alt="link" />
              </ExternalLink>
            ))}
          </SocialIcons>
        </StyledContainer>
      </Nav>
    );
  }
}

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 60px;
    height: 60px;

    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    transition: transform 0.5s ease-in-out;

    :hover {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
      margin: 0 4px;
      width: 50px;
      height: 50px;
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.color.black.regular} @media
    (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;


export default Navbar;
