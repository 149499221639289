import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const About = () => (
  <StyledSection id="cta">
    <Container
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Headline>Când e gata cafeaua?</Headline>
      <Description>
        Încă sunt în căutarea celei mai bune cafele, dar canalul de Youtube este
        gata. Până așez masa, scaunele, pornesc camerele și dau cafeaua în
        clocot, tu poți să mi te alături și să fii printre primii care ajung la
        zaț.
      </Description>
      <StyledExternalLink href="https://www.youtube.com/channel/UCnSoBC7gdEQxaBcgGoXa_2Q?view_as=subscriber">
        Abonează-te la zaț
      </StyledExternalLink>
    </Container>
  </StyledSection>
);

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.black.regular};
  padding-bottom: 30px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 48px 0;
  }
`;

const Headline = styled.h2`
  color: ${props => props.theme.color.white.regular};
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 60px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.lg}) {
    font-size: 45px;
    line-height: 49px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const Description = styled.p`
  color: ${props => props.theme.color.white.regular};
  font-size: ${props => props.theme.font_size.regular};
  font-weight: 300;
  margin-bottom: 60px;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${props => props.theme.color.white.regular};
  font-weight: 300;
  border: 1px solid ${props => props.theme.color.white.regular};
  padding: 25px 40px;
  text-decoration: none;
  box-sizing: content-box;

  &:hover {
    color: ${props => props.theme.color.white.regular};
    border: 0px;
    padding: 25px 40px 10px 40px;
    border-bottom: 15px solid ${props => props.theme.color.secondary};
  }
`;


export default About;
