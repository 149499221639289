import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';

const LongStory = () => (
  <StaticQuery
    query={graphql`
      query {
        cafeaua_care_cade: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "cafeaua_care_cade" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Grid>
            <Art>
              <Img fluid={data.cafeaua_care_cade.childImageSharp.fluid} />
            </Art>
            <Text>
              <Description>Hola!</Description>

              <Description>
                Ăsta ar trebui să fie un text în care să îți spun că Până la zaț
                a fost mereu visul meu, că întotdeauna mi-am dorit să fac asta
                și că după lupte lungi cu mine am reușit să fac ceva. Nu pentru
                că altceva n-a fost. Ci pentru că așa trebuia sa fie. Parțial e
                adevărat, căci îmi doream să am ceva al meu, ceva care să îmi
                mulțumească sufletul și ambițiile. Și iată că acum se întâmplă.
                Și se întâmplă Până la zaț
              </Description>

              <Description>
                De ce Până la zaț? Cum Până la zaț? Până la zaț ce? Poate astea
                sunt întrebările pe care ți le pui acum. Ideea mi-a încolțit în
                minte prin 2018, după ce prinsesem gustul conversațiilor foarte
                faine pe care le tot aveam cu oameni mai mult sau mai puțin
                cunoscuți, prin prisma meseriei mele. Conversații cu oameni
                mișto! Evrica! Asta era! Dar de ce să le fac? Cum să le fac? În
                ce context? Fac interviuri pe YouTube? Am simțit că asta ar fi
                calea și în timpul care a urmat am tot presat la idee.
              </Description>

              <Description>
                Fast forward un an și deja începusem să cunosc mai bine lumea și
                scena cafelei de specialitate din București și din alte mari
                orașe. Am rămas fermecat. Era genul de atmosferă la care tânjeam
                în conversațiile și în interviurile mele. Mi-am zis că ăsta e
                liantul: cafeaua. În felul ăsta am ajuns să gândesc, alături de
                prietenul meu Ionuț, ideea unui spațiu neconvențional - și
                totuși în online - unde pot avea parte de conversații cu oameni
                faini, la cafea, și unde putem să vorbim chestii de orice fel.
                Iar cum orice discuție durează până ce cafeaua ajunge Până la
                zaț... Înțelegeți unde bat cu asta, da?
              </Description>

              <Description>
                Așa s-a ajuns Până la zaț. Un spațiu, un canal de YouTube, un
                loc de discuții libere despre orice, cu oameni de orice fel. La
                fel cum se întâmplă când mergi să îți iei o cafea, te întâlnești
                cu prieteni sau cunoști oameni noi, iar totul e întregit de
                aroma cafelei.
              </Description>

              <Description>
                Nu e un podcast, nu e doar un canal de YouTube unde o să vezi și
                o să auzi interviuri, ci e un loc de întâlnire, la o cană de
                cafea, unde discut. Și aici rămâne punctul. Discut. Cu oameni de
                tot felul, oameni care merită și care au ceva de spus, oameni de
                la care să înveți ceva. Discuții în urma cărora tu să rămâi cu
                ceva. Pentru că, atunci când ajungi Până la zaț, contează ce
                rămâne. Sau rămâne ce contează? Alege tu.
              </Description>

              <Description>
                Sper să îți placă ceea ce o să auzi și o să vezi și sper să mă
                susții în orice mod vrei tu. Ăsta sunt eu. Ăsta e Până la zaț.
              </Description>
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.color.white.dark};

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 12px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 100%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const Description = styled.p`
  color: ${props => props.theme.color.black.regular};
  font-size: ${props => props.theme.font_size.regular};
  font-weight: 300;
  margin-bottom: 30px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export default LongStory;
