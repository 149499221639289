import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';
import InternalLink from '@common/InternalLink';

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "vic" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Container>
          <Grid>
            <Art>
              <Img fluid={data.art_build.childImageSharp.fluid} />
            </Art>
            <Text>
              <Headline>Hai să ne bem cafeaua până la zaț.</Headline>
              <Description>
                Până la Zaț e o sesiune de sorbit povești atipice din
                universul urban românesc. Stăm la cafea cu diverși oameni și
                povestim ca între prieteni despre lucruri. Ne simțim bine,
                suntem noi înșine și dăm mai departe o ceașcă de inspirație.
              </Description>
              <StyledInternalLink href="#cta">
                Când e gata cafeaua?
              </StyledInternalLink>
            </Text>
          </Grid>
        </Container>
      </HeaderWrapper>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 150px !important;

  @media (max-width: ${props => props.theme.screen.lg}) {
    padding-top: 100px;
  }
`;

const Art = styled.figure`
  width: 80%;
  margin: 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }

  > div {
    width: 120%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledInternalLink = styled(InternalLink)`
  color: ${props => props.theme.color.white.regular};
  font-weight: 300;
  border: 1px solid ${props => props.theme.color.white.regular};
  padding: 25px 40px;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    color: ${props => props.theme.color.white.regular};
    border: 0px;
    padding: 25px 40px 10px 40px;
    border-bottom: 15px solid ${props => props.theme.color.secondary};
  }
`;

const Headline = styled.h1`
  color: ${props => props.theme.color.white.regular};
  font-size: ${props => props.theme.font_size.xlarge};
  font-weight: 600;
  margin-bottom: 30px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    font-size: 44px;
    line-height: 48px;
  }
`;

const Description = styled.p`
  color: ${props => props.theme.color.white.regular};
  font-size: ${props => props.theme.font_size.regular};
  font-weight: 300;
  margin-bottom: 65px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: ${props => props.theme.screen.lg}) {
    font-size: 22px;
    line-height: 24px;
  }
`;

export default Header;
