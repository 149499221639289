import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        cafeaua_aia_buna: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "cafeaua_aia_buna" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        toby_the_cat: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "toby_the_cat" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        povesti_care_merita: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "povesti_care_merita" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <StyledSection id="about">
        <Container>
          <Headline style={{ textAlign: 'center' }}>În trei sintagme</Headline>
          <Grid>
            <Art>
              <Img fluid={data.cafeaua_aia_buna.childImageSharp.fluid} />
            </Art>
            <Art>
              <Img fluid={data.toby_the_cat.childImageSharp.fluid} />
            </Art>
            <Art style={{ width: '75%' }}>
              <Img fluid={data.povesti_care_merita.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Headline>Mai pe lung</Headline>
        </Container>
      </StyledSection>
    )}
  />
);

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.white.dark};
  padding-bottom: 10px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 48px 0;
  }
`;

const Headline = styled.h2`
  color: ${props => props.theme.color.black.regular};
  font-size: 40px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 60px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    font-size: 45px;
    line-height: 49px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 36px;
    line-height: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 60px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 100px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 360px;
  width: 100%;
`;

export default About;
